import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Mission(props) {
    return (
       <Container fluid="true" className="mt-3">
           <Row className="mx-0">
               <Col md={7} lg={8} sm={12}>
                   <div>
                       <h4 className="blackTitle">
                           WELCOME TO EWU ENVIRONMENTAL AND SOCIAL CLUB
                       </h4>
                       <p className="blackDescription">
                           East West University Environmental and Social Club was formed on 14 October, 1999. Since its
                           inception, this club has been pursuing its moto, "SAVE LIFE , SAVE EARTH" through different
                           environmental and social activities. It has been involved with social support programs like
                           rehabilitation of the people in destitute, exploring the talents of the young generation,
                           encouraging the youth in afforestation, community building, etc. for the last 24 years.
                       </p>
                   </div>
                   <div>
                       <h4 className="blackTitle">OUR MISSION</h4>
                       <p className="blackDescription">
                           Our mission is to build a progressive platform for Robotics enthusiasts by active
                           participation in
                           research, training and contest. We do it by organizing various programs, workshops and
                           competitions that help us create connections among students, teachers and employers.
                       </p>
                   </div>
                   <div>
                       <h4 className="blackTitle">ACTIVITIES AT A GLANCE :</h4>
                       <p className="blackDescription">
                           Tree Plantation Program, Sapling Distribution Program, Observance of  Environmental Week at Campus, Quiz and Photography
                           Competition on Environment, Environmental Educational Programs,  Rehabilitation of Underprivileged People,  Eid Gift Distribution Program Among the People in Destitute, Essay Writing Competition for Young Thinkers, Children's Art Competition on Environmental Issues, Workshops on Social Mobilization
                           for University Students, Social Plan Competition, Workshops on Social Issues,
                           Advocacy Program, Blood Donation Program, Medical Camps, Relief Distribution,
                           Distribution of Winter Clothes, Voluntary Works with BAPA, Fund Raising Programs for Needy
                           University Students' and Staff,  Workshop on Social Business, etc.
                       </p>
                   </div>

               </Col>
               <Col md={5} lg={4} sm={12}>
                   <Row>
                       <Col md={5} lg={5} sm={5}>
                           <img className="eventImg"
                                src="https://clubs.ewubd.edu/storage/app/uploads/public/669/171/59e/66917159e8f07302051282.jpg"
                                alt=""/>
                       </Col>
                       <Col md={7} lg={7} sm={7}>
                           <h3 className="blackTitle">Day Long Tour 2024 Registration on Going</h3>
                           <h4 className="blackDescription"> Knock! Knock! ESCIANS,
                               Feeling stressed from classes? Amid our busy university lives, it's essent...</h4>
                       </Col>
                   </Row>
                   <Row className="mt-sm-2">
                       <Col md={5} lg={5} sm={5}>
                           <img className="eventImg"
                                src="https://clubs.ewubd.edu/storage/app/uploads/public/667/9be/6d9/6679be6d9eddc264438928.jpg"
                                alt=""/>
                       </Col>
                       <Col md={7} lg={7} sm={7}>
                           <h3 className="blackTitle">Day Long Tour 2024 Registration on Going</h3>
                           <h4 className="blackDescription"> Knock! Knock! ESCIANS,
                               Feeling stressed from classes? Amid our busy university lives, it's essent...</h4>
                       </Col>
                   </Row>
               </Col>
           </Row>
       </Container>
    );
}

export default Mission;
