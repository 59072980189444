import React, {Fragment, useState} from 'react';
import { Container, Row, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import axios from "axios";
import {toast} from "react-toastify";
import base_url from "../appRoute";


function RegistrationForm() {
    const { register, handleSubmit, formState: { errors },reset } = useForm();
    const [loading,setLoading] = useState(false);
    const onSubmit = (data) => {
        setLoading(true);
        axios.post(`${base_url}/api/competitor/saveCompetitor`,data,{
            headers:{
                "authorization":'jurc@123'
            }
        }).then((res)=>{
            console.log(res.data);
            reset()
            setLoading(false);
            // reset();
            toast.success("Registration successful.");
        }).catch((err)=>{
            setLoading(false);
            if(err.response.status===409){
                toast.error("This team already exist.");
            }else{
                toast.error("Try later.")
            }
        })
    };

    const registrationForm = [
        {id:0,type:"text",label:"Enter team name.", name: "team", required: true, placeholder: "Team name."},
        { id: 1, type: "text", label: "Enter user email.", name: "email", required: true, placeholder: "User email." },
        { id: 2, type: "select", label: "Select department.", name: "department", required: true, placeholder: "Department",
            options: ["CSE", "IIT", "STATISTICS", "PHYSICS", "CHEMISTRY", "MATH", "ENV",]
        },

        { id: 3, type: "text", label: "Enter user name one.", name: "name1", required: true, placeholder: "User name one." },
        { id: 4, type: "select", label: "Enter batch", name: "batch1", required: true, placeholder: "batch",
            options: [52, 51, 50, 49, 48, 47, 46, 45] },

        { id: 5, type: "text", label: "Enter user name two.", name: "name2", required: true, placeholder: "User name two." },
        { id: 6, type: "select", label: "Enter batch", name: "batch2", required: true, placeholder: "batch",
            options: [52, 51, 50, 49, 48, 47, 46, 45] },

        { id: 7, type: "text", label: "Enter user name three.", name: "name3", required: true, placeholder: "User name three." },
        { id: 8, type: "select", label: "Enter batch", name: "batch3", required: true, placeholder: "batch",
            options: [52, 51, 50, 49, 48, 47, 46, 45] },
    ];

    return (
        <Container fluid="true" className="mt-3 px-5 text-dark">
            <h1 className="subTitle text-center text-dark">Register to participate website competition.</h1>
            <Row className="mx-0 mt-3">
                <form className="box p-3" onSubmit={handleSubmit(onSubmit)}>
                    {registrationForm.map((data, idx) => (
                        <Fragment key={data.id}>
                            {data.type === "select" ? (
                                <div className="mb-2">
                                    <label htmlFor={data.name} className="form-label mb-0 blackDescription">{data.label}</label>
                                    <select
                                        id={data.name}
                                        className="form-control blackDescription"
                                        {...register(data.name, { required: data.required })}
                                    >
                                        <option value="">Select {data.placeholder.toLowerCase()}</option>
                                        {data.options.map(option => (
                                            <option key={option} value={option}>{option}</option>
                                        ))}
                                    </select>
                                    {errors[data.name] && <span className="text-danger">This field is required</span>}
                                </div>
                            ) : (
                                <div key={data.id} className="mb-2">
                                    <label htmlFor={data.name} className="form-label mb-0">{data.label}</label>
                                    <input
                                        type={data.type}
                                        id={data.name}
                                        className="form-control"
                                        {...register(data.name, { required: data.required })}
                                        placeholder={data.placeholder}
                                    />
                                    {errors[data.name] && <span className="text-danger">This field is required</span>}
                                </div>
                            )}
                        </Fragment>
                    ))}

                    <div>
                        {
                            loading ? (
                                <div>Loading...</div>
                            ):(
                                <Button type="submit" variant="outline-primary">Submit</Button>
                            )
                        }
                    </div>
                </form>
            </Row>
        </Container>
    );
}

export default RegistrationForm;
