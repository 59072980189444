import React, { useState, useEffect } from 'react';
import { Dropdown, Nav, Navbar } from 'react-bootstrap';
import logo from '../Assets/Images/logo.jpg';
import {Link} from "react-router-dom";

function Menu(props) {
    const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 40) {
                setIsSticky(true);
            } else {
                setIsSticky(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <Navbar expand="sm" sticky={isSticky ? "top" : ""} className="mainNavBody">
            <Navbar.Brand href="#">
                <img className="logo" src={logo} alt="logo" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
                <Nav className="ms-auto" navbarScroll>
                    <Nav.Link as={Link} to="/" className="navLink">
                        Home
                    </Nav.Link>
                    <Nav.Link className="navLink" href="#action2">
                        jucse
                    </Nav.Link>
                    <Dropdown>
                        <Dropdown.Toggle className="dropDown navLink" id="dropdown-basic">
                            Events
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="dropMenu">
                            <Dropdown.Item className="navLink" as={Link} to="/registration">
                                Website Competition
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <Nav.Link className="navLink" href="#">
                        Contest
                    </Nav.Link>
                    <Nav.Link className="navLink" href="#">
                        Gallery
                    </Nav.Link>
                    <Nav.Link className="navLink" href="#">
                        Members
                    </Nav.Link>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Menu;
