import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function EventDetails(props) {
    return (
        <Container fluid="true" className="mt-4 w-75 mx-auto">
            <Row className="mx-0">
                <Col lg={6} md={6} sm={6}>
                    <h3 className="subTitle text-dark">Price money</h3>
                    <h4 className="blackDescription">First price : 5000 BDT</h4>
                    <h4 className="blackDescription">Second price : 1000 BDT</h4>
                    <h4 className="blackDescription">Third price : 1000 BDT</h4>
                    <h4 className="blackDescription">Fourth price : 1000 BDT</h4>
                </Col>
                <Col lg={6} md={6} sm={6}>
                    <h3 className="subTitle text-dark">Description</h3>
                    <h4 className="blackDescription">Registration starts on : date</h4>
                    <h4 className="blackDescription">Registration ends on : date</h4>
                </Col>
            </Row>
            <Row className="mx-0 mt-4">
                <h3 className="subTitle text-dark">Process</h3>
                <ul className="blackDescription text-dark mt-2">
                    <li>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, odio?</li>
                    <li>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, odio?</li>
                    <li>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, odio?</li>
                    <li>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, odio?</li>
                    <li>Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Animi, odio?</li>

                </ul>
            </Row>
        </Container>
    );
}

export default EventDetails;
