import React from 'react';
import { Carousel } from 'react-bootstrap';
import img1 from "../Assets/Images/Banner/car.jpg"
import img2 from "../Assets/Images/Banner/car1.jpg"
import img3 from "../Assets/Images/Banner/car2.jpg"


function TopBanner(props) {
    return (
        <Carousel fade interval={6000} controls={false} indicators={false} >
            <Carousel.Item className="zoom ">
                <img
                    className="d-block w-100"
                    src={img1}
                    alt="First slide"
                />
                <Carousel.Caption className="caption-top-center">
                    <h3 className="title">First Slide</h3>
                    <p className="description">Description for the first slide.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="zoom">
                <img
                    className="d-block w-100"
                    src={img2}
                    alt="Second slide"
                />
                <Carousel.Caption className="caption-top-center">
                    <h3 className="title">Second Slide</h3>
                    <p className="description">Description for the second slide.</p>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item className="zoom">
                <img
                    className="d-block w-100"
                    src={img3}
                    alt="Third slide"
                />
                <Carousel.Caption className="caption-top-center">
                    <h3 className="title">Third Slide</h3>
                    <p className="description">Description for the third slide.</p>
                </Carousel.Caption>
            </Carousel.Item>
        </Carousel>
    );
}

export default TopBanner;
