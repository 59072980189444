import React from 'react';
import {Col, Container, Row} from "react-bootstrap";

function Footer(props) {
    return (
        <Container fluid="true" className="mt-3">
            <Row className="mx-0">
                <Col>
                    <h5>Contact Us</h5>
                    <ul className="list-unstyled">
                        <li>Email: info@jurobotics.com</li>
                        <li>Phone: +123 456 7890</li>
                        <li>Address: 123 Robotics St., JU Campus</li>
                    </ul>
                </Col>
                <Col>
                    <h5>Follow Us</h5>
                    <ul className="list-unstyled">
                        <li><a href="#a">Facebook</a></li>
                        <li><a href="#a">Twitter</a></li>
                        <li><a href="#a">Instagram</a></li>
                    </ul>
                </Col>
                <Col >
                    <h5>Quick Links</h5>
                    <ul className="list-unstyled">
                        <li><a href="#a">Home</a></li>
                        <li><a href="#a">About</a></li>
                        <li><a href="#a">Events</a></li>
                        <li><a href="#a">Contact</a></li>
                    </ul>
                </Col>
            </Row>
        </Container>
    );
}

export default Footer;
