import React from 'react';
import { Button, Nav, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faGithub } from "@fortawesome/free-brands-svg-icons";
import {Link} from "react-router-dom";

function TopMenu(props) {
    return (
        <Nav className="topNav">
            <Row className="w-100">
                <Col sm={8} className="mt-2 d-flex justify-content-sm-center justify-content-lg-center justify-content-md-end align-items-center">
                    <h4 className="subTitle"><span>Email : </span>jurobotics@gmail.com</h4>
                    <h4 className="subTitle mx-3"><span>Hotline : </span>017283884380</h4>
                </Col>
                <Col sm={4} className="d-flex justify-content-start align-items-center">
                    <div className="topIconDiv mx-3">
                        <a href="#abc"><FontAwesomeIcon className="icon" icon={faFacebook} /></a>
                        <a href="#abc"><FontAwesomeIcon  className="icon mx-3" icon={faGithub}/></a>
                    </div>
                    <Button as={Link} to="/registration" className="joinBtn">JOIN</Button>
                </Col>
            </Row>
        </Nav>
    );
}

export default TopMenu;
